import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

const Image = function ({styles = {}, ...props}) {
  return <StaticQuery
    query={graphql`
      query {
        images: allFile(filter: { sourceInstanceName: { eq: "images" } }) {
          edges {
            node {
              extension
              relativePath
              childImageSharp {
              fluid(maxWidth: 1000) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    }
    `}

    render={(data) => {
      const originImage = data.images.edges.find(
        image => {
          return image.node.relativePath === props.src
        }
      );

      const size = {}

      if (props.width) {
        size.width = props.width;
      }

      if (props.height) {
        size.height = props.height;
      }

      if (!originImage || !originImage.node || !originImage.node.childImageSharp) {
        console.error("Image not found", props.src);
        return null;
      }

      if (!size) {
        return (
          <Img
            fluid={originImage.node.childImageSharp.fluid}
            {...props}
          />
        );
      }

      return (
        <div style={{ width: size.width, height: size.height, position: 'relative', ...styles }}>
          <Img
            fluid={originImage.node.childImageSharp.fluid}
            {...props}
          />
        </div>
      );
    }}
  />
}

export default Image;
